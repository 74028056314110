export const fetchSafetyMessages = async () => {
    try {
        const response = await fetch('https://vehiclesafety.idea2reality.tech/api/safety_message');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (Array.isArray(data.safetymessages)) {
            return data.safetymessages;
        } else {
            throw new Error('Invalid API response format. Expected an array of messages.');
        }
    } catch (error) {
        return new Error('Error fetching safety messages:', error);
    }
};


export const sendMessageApi = async (data) => {
    try {
        const response = await fetch(
            "https://vehiclesafety.idea2reality.tech/api/send_message",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        const responseData = await response.json();

        return { responseData, status: response.status };
    } catch (error) {
        console.error("Error sending otp :", error);
        return error;
    }
};