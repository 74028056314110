import React, { useEffect, useState } from "react";
import car from "../assets/images/car.png";
import MobileFooterNavbar from "../components/MobileFooterNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchQRDetails,
  sendOtpApi,
  vehicleverifyApi,
  verifyOtpApi,
} from "../service/MessageApi/VehicleVerifyApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";

const VehicleVerify = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [vehicleInputNumber, setVehicleInputNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [qrcode, setQrCode] = useState("");

  const navigate = useNavigate();
  const location = useLocation();


  //  useEffect(() => {
  //    const urlParams = new URLSearchParams(location.search);
  //    const codeValue = urlParams.get("code");
  //    if (codeValue) {
  //      setQrCode(codeValue);
  //    }
  //  }, [location.search]);

  const handleUserDataChange = (event) => {
    event.preventDefault();
    setMobileNumber(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleChangeVehicleNumber = (event) => {
    setVehicleInputNumber(event.target.value);
  };

  const handleSendOtp = async () => {
    const data = {
      phone_number: `+91${mobileNumber}`,
      qr_code_id: qrcode,
    };
    setLoading(true);
    const res = await sendOtpApi(data);

    if (res.status === 200) {
      toast.success(`${res?.responseData?.otp} is your OTP`, {
        position: "top-center",
      });
      setOtpSent(true);
      setLoading(false);
    } else {
      toast.error(
        `${res?.responseData?.message || res?.error || "Network Error"}`,
        {
          position: "top-center",
        }
      );
      setLoading(false);
    }
  };

  const handleOtpVerify = async () => {
    const data = {
      phone_number: `+91${mobileNumber}`,
      qr_code_id: qrcode,
      otp: otp,
    };
    setLoading(true);
    const res = await verifyOtpApi(data);
    if (res.status === 200) {
      toast.success(`${res.responseData.message} ✉️`, {
        position: "top-center",
      });
      setIsDisable(true);
      setLoading(false);
    } else {
      toast.error(
        `${res?.responseData?.message || res?.error || "Network Error"}`,
        {
          position: "top-center",
        }
      );
      setIsDisable(false);
      setLoading(false);
    }
  };

  const handlevehicleVerify = async () => {
    const data = {
      vehicle_number: vehicleInputNumber,
      qr_code_id: qrcode,
    };
    setLoading(true);
    const res = await vehicleverifyApi(data);
    if (res.status === 200) {
      toast.success(`${res.responseData.message} ✉️`, {
        position: "top-center",
      });
      navigate("/ContactOwner", { state: { data: qrcode } });
      setIsPopupVisible(true); // Example of showing a popup after vehicle verification
      setIsDisable(false);
      setLoading(false);
    } else {
      toast.error(
        `${res?.responseData?.message || res?.error || "Network Error"}`,
        {
          position: "top-center",
        }
      );
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center mt-5 h-auto ">
      <div className="bg-blue-50 p-8 rounded-xl shadow-md w-full max-w-lg ">
        <h2 className="text-2xl font-bold mb-4 flex justify-center">
          Verify Vehicle
        </h2>
        <div className="mb-4">
          <img className="h-48 w-88 mx-auto rounded-md" src={car} alt="Car" />
          <p className="text-md font-semibold mt-4 flex justify-center">
            Please verify the plate number of the vehicle
          </p>
          <div className="mt-4">
            <p className="text-lg font-semibold mb-2">
              Do you want the vehicle owner to call you? you can enter your
              number here.
            </p>

            <div className="flex items-center justify-between mb-4">
              <div className="relative w-full">
                <input
                  maxLength={10}
                  className="w-full border rounded-md py-2 px-0 pl-3 text-gray-700 focus:outline-none focus:border-blue-500"
                  placeholder="Enter your phone number"
                  value={mobileNumber}
                  onChange={handleUserDataChange}
                />
                <button
                  onClick={handleSendOtp}
                  className={`absolute inset-y-0 right-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r`}>
                  {otpSent ? "Resend" : "Send OTP"}
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between mb-4">
              <div className="relative w-full">
                <input
                  type="text"
                  className="w-full border rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
                  placeholder="Enter your OTP"
                  value={otp}
                  onChange={handleOtpChange}
                  maxLength={4}
                  inputMode="numeric"
                />
                <button
                  onClick={handleOtpVerify}
                  className={`absolute inset-y-0 right-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r`}>
                  Verify
                </button>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <p className="text-lg font-semibold">
            Please enter the last 4 digits of the vehicle
          </p>
          <div className="flex items-center justify-between mt-4">
            <div>
              <p className="text-lg font-semibold">UP85BF</p>
              <div className="bg-blue-500 rounded-md w-16 h-8 flex items-center justify-center mt-0">
                <p className="text-lg font-semibold"># # # #</p>
              </div>
            </div>
            <input
              maxLength={4}
              className="w-2/4 border rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
              placeholder="Enter last 4 digits"
              value={vehicleInputNumber}
              onChange={handleChangeVehicleNumber}
            />
          </div>
        </div>
        <button
          onClick={handlevehicleVerify}
          disabled={!isDisable}
          className={`mt-8 mb-28 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
            isDisable ? "" : "opacity-50 cursor-not-allowed"
          }`}>
          Submit
        </button>
      </div>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-white">
            <i className="fas fa-spinner fa-spin text-4xl"></i>
            <Loader
              visible={true}
              height={100}
              width={100}
              color="#478ffd"
              ariaLabel="three-circles-loading"
              wrapperStyle={{}}
            />
          </div>
        </div>
      )}
      {isPopupVisible && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md">
            <p className="text-lg font-semibold">Message Sent Successfully</p>
          </div>
        </div>
      )}
      <MobileFooterNavbar />
    </div>
  );
};

export default VehicleVerify;
