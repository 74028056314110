import React, { useEffect, useState } from 'react';
import { RxCrossCircled } from "react-icons/rx";
import MobileFooterNavbar from '../components/MobileFooterNavbar';
import { fetchSafetyMessages } from '../service/MessageApi/MessageApi';
import Loader from '../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchQRDetails } from '../service/MessageApi/VehicleVerifyApi';

const EmergencyContact = ({ qrcode }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [safetyMessages, setSafetyMessages] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [emergency, setEmergencyDetails] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        fetchQRDetails("QR1004").then((data) => {
          const isActive = data?.data[0]?.is_active;
          const vehicle_id = data?.data[0]?.vehicle_id;
         
          if (isActive === 1 && vehicle_id) {
            const productCategory = data.data[0].product_category_name;

            const vcehiclenum = data.data[0].vehicle_number;
            const {
              mobile_number,
              emergency2_relation,
              emergency1_relation,
              emergency1_relative,
              emergency2_relative,
              emergency_number1,
              emergency_number2,
              emergency2_relation_name,
              health_issue,
              policy_number,
              emergency1_relation_name,
              blood_group,
            } = data.data[0];

            const emergencyDetails = {
              mobile_number ,
              emergency2_relation,
              emergency1_relation,
              emergency1_relative,
              emergency2_relative,
              emergency_number1,
              emergency1_relation_name,
              emergency2_relation_name,
              emergency_number2,
              health_issue,
              policy_number,
              blood_group,
            };
            console.log(data);
            setEmergencyDetails(emergencyDetails);
          }
        });
        setLoading(true);
        const messages = await fetchSafetyMessages();
        setSafetyMessages(messages);
        setLoading(false);
      } catch (error) {
        // Handle error
        console.error("Error fetching safety messages:", error);
        toast.error(`Error fetching safety messages ✉️`, {
          position: "top-center",
        });
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleMessageSendModal = () => {
    setModalVisible(true);
  };

  const handleMessageSendModal1 = () => {
    setModalVisible1(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleCloseModal1 = () => {
    setModalVisible1(false);
  };

  const handleTabSelection = (tab) => {
    // Handle tab selection
  };

  const handleRadioPress = (value) => {
    setSelectedValue(value);
  };

  const handleSendeMessage = () => {
    toast.success(`${"Message Send Successfully"}`, {
      position: "top-center",
    });
    setModalVisible1(false);
    setModalVisible(false);
    navigate("/");
  };
  const visibleSafetyMessages = safetyMessages.slice(0, 8);

  return (
    <div className="bg-blue-50">
      <ToastContainer />
      <div className="flex flex-col justify-center p-4">
        <div className="bg-gradient-to-b from-blue-200 to-blue-200 rounded-lg p-4">
          <div className="text-lg font-semibold">Emergency details</div>
          <div className="text-base">Blood Group : N/A</div>
          <div className="text-base">Health issue : N/A</div>
          <div className="text-base">Insurance No : N/A</div>
        </div>
      </div>

      <div className="mx-4 my-2">
        <p className="text-xl font-bold text-black">Emergency Contacts</p>
      </div>

      <div className="flex flex-col justify-center p-4">
        <div className="bg-gradient-to-b from-blue-200 to-blue-200 rounded-lg p-4">
          <p className="font-bold text-lg">Emergency contact</p>
          {/* <p className="font-semibold">Relative Name: {emergencyDetails?.emergencyDetails?.emergency1_relative || 'N/A'}</p> */}
          <p className="font-semibold">
            Relative Name:{" "}
            {emergency?.emergency1_relative
              ? emergency?.emergency1_relative
              : "N/A"}
          </p>
          <p className="font-semibold">
            Relation:{" "}
            {emergency?.emergency1_relation_name
              ? emergency?.emergency1_relation_name
              : "N/A"}
          </p>
          <div className="flex flex-row justify-between mt-6">
            <div
              onClick={handleMessageSendModal}
              className="w-44 rounded bg-blue-500 items-center">
              <p className="text-white font-semibold py-2 text-center">
                Message
              </p>
            </div>
            <div className="w-44 rounded bg-blue-700 bg-opacity-50 items-center">
              <p className="text-white font-semibold py-2 text-center">
                Private Call
              </p>
            </div>
          </div>
        </div>

        {/* Modal */}
        {modalVisible && (
          <div className="fixed top-0 left-0 w-full h-full bg-black opacity-20 z-50"></div>
        )}
        <div
          className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 ${
            modalVisible ? "block" : "hidden"
          }`}>
          <div className="bg-white rounded-lg p-6 w-auto shadow-lg">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold">Select Message</h2>
              <button onClick={handleCloseModal}>
                <RxCrossCircled />
              </button>
            </div>

            {/* <div className="h-px bg-gray-300 w-full mb-6 flex justify-center"> */}
            {/* Here messages will come */}
            <div className="h-80 w-[280px] my-2 overflow-y-auto">
              {visibleSafetyMessages.map((message) => (
                <label key={message.message_id} className="block ">
                  <div className="flex items-center justify-between m-2">
                    <div className="flex justify-between items-center">
                      <img
                        style={{ height: 20, width: 20, marginRight: 4 }}
                        src={message.image_path}
                        alt="image"
                      />
                      <span className="text-base font-normal text-black">
                        {message.message}
                      </span>
                    </div>
                    <input
                      type="radio"
                      className="mr-2 form-radio"
                      value={message.message_id}
                      checked={selectedValue === message.message_id}
                      onChange={() => handleRadioPress(message.message_id)}
                    />
                  </div>
                </label>
              ))}
            </div>
            {/* </div> */}

            <div className="flex justify-center">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md mb-6 "
                onClick={handleSendeMessage}>
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center p-4">
        {emergency?.emergency2_relative ? (
          <div className="bg-gradient-to-b from-blue-200 to-blue-200 rounded-lg p-4">
            <p className="font-bold text-lg">Emergency contact</p>
            {/* <p className="font-semibold">Relative Name: {emergencyDetails?.emergencyDetails?.emergency1_relative || 'N/A'}</p> */}
            <p className="font-semibold">
              Relative Name:
              {emergency?.emergency2_relative
                ? emergency?.emergency2_relative
                : "N/A"}
            </p>
            <p className="font-semibold">
              Relation:{" "}
              {emergency?.emergency2_relation_name
                ? emergency?.emergency2_relation_name
                : "N/A"}
            </p>
            <div className="flex flex-row justify-between mt-6">
              <div
                onClick={handleMessageSendModal1}
                className="w-44 rounded bg-blue-500 items-center">
                <p className="text-white font-semibold py-2 text-center">
                  Message
                </p>
              </div>
              <div className="w-44 rounded bg-blue-700 bg-opacity-50 items-center">
                <p className="text-white font-semibold py-2 text-center">
                  Private Call
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        
        {modalVisible1 && (
          <div className="fixed top-0 left-0 w-full h-full bg-black opacity-20 z-50"></div>
        )}
        <div
          className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 ${
            modalVisible1 ? "block" : "hidden"
          }`}>
          <div className="bg-white rounded-lg p-6 w-80 shadow-lg">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold">Select Message</h2>
              <button onClick={handleCloseModal1}>
                <RxCrossCircled />
              </button>
            </div>

            <div className="h-80 mx-4 my-2 overflow-y-auto">
              {visibleSafetyMessages.map((message) => (
                <label key={message.message_id} className="block">
                  <div className="flex items-center justify-between m-2">
                    <div className="flex items-center">
                      <img
                        style={{ height: 20, width: 20, marginRight: 2 }}
                        src={message.image_path}
                        alt="image"
                      />
                      <span className="text-base font-normal text-black">
                        {message.message}
                      </span>
                    </div>
                    <input
                      type="radio"
                      className="mr-2 form-radio"
                      value={message.message_id}
                      checked={selectedValue === message.message_id}
                      onChange={() => handleRadioPress(message.message_id)}
                    />
                  </div>
                </label>
              ))}
            </div>

            <div className="flex justify-center">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md mb-6 "
                onClick={() => handleTabSelection("first")}>
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-4 my-2 mb-28">
        <p className="text-sm font-normal">
          Please make sure that you are sending the right message. You are
          helping us create a safer and nicer community for yourself and those
          around you.
          <br />
          <span className="italic font-bold">
            Thank you for using Find The Owner
          </span>
        </p>
      </div>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-white">
            <i className="fas fa-spinner fa-spin text-4xl"></i>
            <Loader
              visible={true}
              height={100}
              width={100}
              color="#478ffd"
              ariaLabel="three-circles-loading"
              wrapperStyle={{}}
            />
          </div>
        </div>
      )}
      <MobileFooterNavbar />
    </div>
  );
};

export default EmergencyContact