import React, { useEffect, useState } from 'react';

import scanner from "../assets/images/scanner.jpg";
import MobileFooterNavbar from '../components/MobileFooterNavbar';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader';
import { fetchSafetyMessages } from '../service/MessageApi/MessageApi';


const AccessoryVerifyScreen = ({ navigation }) => {
  const [selectedProduct, setSelectedProduct] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [safetyMessages, setSafetyMessages] = useState([]);
   console.log(safetyMessages);
  const products = [
    { label: 'Key Chain', value: 'key_chain' },
    { label: 'Bag', value: 'bag' },
    { label: 'Mobile', value: 'mobile' }


  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const messages = await fetchSafetyMessages();
        setSafetyMessages(messages);
        setLoading(false);
      } catch (error) {
        // Handle error
        console.error('Error fetching safety messages:', error);
        toast.error(`Error fetching safety messages ✉️`, {
          position: 'top-center',
        });
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  const handleRadioPress = (value) => {
    setSelectedProduct(value);
  };

  const handleButtonPress = () => {
    navigate('/AccessoryMessages')
    // Handle button press logic here
  };

  return (
    <div className="flex justify-center mt-5 h-auto ">
      <div className="bg-blue-50 p-8 rounded-xl shadow-md w-full max-w-lg ">
        <h2 className="text-2xl font-bold mb-4 flex justify-center">Verify Accessory</h2>
        <div className="mb-8">
          <img className="h-48 w-48 mx-auto rounded-xl" src={scanner} alt="Scanner" />
          <p className="text-base font-semibold text-center mt-3">Validate Missing Accessory Item</p>
          <div className="mx-4 mt-8">
            <p className="text-base font-medium">Please select a product.</p>
            <div className="overflow-y-auto max-h-48 mt-4">
              {safetyMessages.map((product, index) => (
                <div key={index} className="flex justify-between items-center p-2 border-b border-gray-300">
                  <label className="flex items-center">
                    <input type="radio" className="mr-2 form-radio" value={product.message_id} checked={selectedProduct === product.message_id} onChange={() => handleRadioPress(product.message_id)} />
                    <span>{product.message}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8">
          <button onClick={handleButtonPress} className="mb-28 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
        </div>
      </div>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-white"><i className="fas fa-spinner fa-spin text-4xl"></i>
            <Loader
              visible={true}
              height={100}
              width={100}
              color="#478ffd"
              ariaLabel="three-circles-loading"
              wrapperStyle={{}}
            />
          </div>
        </div>
      )}
      <MobileFooterNavbar />
    </div>
  );
};

export default AccessoryVerifyScreen;
