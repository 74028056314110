import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { checkDuplicateNumberapi, sendLoginApi } from '../service/MessageApi/VehicleVerifyApi';
import { useNavigate } from 'react-router-dom';

function Login() {
 const [mobileNumber, setMobileNumber] = useState("");
 const [otp, setOtp] = useState(false);
  const [loading, setLoading] = useState(false);
const navigate=useNavigate()
   const handleUserDataChange = (event) => {
     event.preventDefault();
     setMobileNumber(event.target.value);
   };
  
  const handleSendOtp = async () => {
    const data = {
      phone_number: `+91${mobileNumber}`,
     
    };
    setLoading(true);
    console.log((data))
    const response =await checkDuplicateNumberapi(data);
   
    if (response.status === 400) {
      const res = await sendLoginApi(data);
     
      if (res.status === 200) {
        toast.success(`${res?.responseData?.otp} is your OTP`, {
          position: "top-center",
        })
        setLoading(false);
        setInterval(() => {
          
          navigate("/otp", { state: { phone: mobileNumber } });
        }, 2000);
      }
    } else {
      toast.error(
        "Network Error",
        {
          position: "top-center",
        }
      );
      setLoading(false);
    }
   
    setLoading(false);
  };
  

  return (
    <div className="flex justify-center mt-5 h-auto ">
      <div className="bg-blue-50 p-8 rounded-xl shadow-md w-full max-w-lg ">
        <h2 className="text-2xl font-bold mb-4 flex justify-center">LogIn</h2>
        <div className="mb-4">
          <img
            className="h-48 w-88 mx-auto rounded-md"
            src={require("../assets/images/image.png")}
          />
          <p className="text-md font-semibold mt-4 flex justify-center">
            Please LogIn for Activate youre QR
          </p>
          <ToastContainer/>
          <div className="flex items-center justify-between mb-4 mt-4">
            <div className="relative w-full">
              <input
                maxLength={10}
                className="w-full border rounded-md py-2 px-0 pl-3 text-gray-700 focus:outline-none focus:border-blue-500"
                placeholder="Enter your phone number"
                value={mobileNumber}
                onChange={handleUserDataChange}
              />
            </div>
          </div>
              <button
                onClick={handleSendOtp}
                className={`w-full mt-10 inset-y-0 right-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`}>
                Send OTP
              </button>
        </div>
      </div>
    </div>
  );
}

export default Login
