const PricingCard = ({
    children,
    description,
    price,
    type,
    subscription,
    buttonText,
    active,
  }) => {
    return (
      <>
        <div className="w-[340px] md:w-[380px] lg:w-[320px] xl:w-[380px]" >
          <div className="relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke bg-white px-6 py-10 shadow-pricing  sm:p-12 lg:px-4 lg:py-10 xl:p-[40px]">
            <span className="mb-3 block text-gray-700 text-lg font-semibold">
              {type}
            </span>
            <div className="flex gap-2">
            <span className="text-[32px] mt-1 font-light">Rs:</span>
            <h2 className="mb-2 text-[42px] font-bold text-dark">
               {price}
              <span className="text-sm font-medium text-gray-500 ">
                / {subscription}
              </span>
            </h2>
            </div>
            <p className="mb-8 text-gray-500 border-b border-stroke pb-8 text-sm text-body-color">
              {description}
            </p>
              <div className="flex items-center">
             <div className="mb-9 text-gray-700 flex flex-col gap-[10px]">
             {children}</div>
             </div>

            <a
              href="/#"
              className={` ${
                active
                  ? "block w-full rounded-md border border-primary bg-primary p-3 text-center text-base font-medium bg-black text-white transition hover:bg-opacity-90"
                  : "block w-full rounded-md border border-stroke bg-transparent p-3 text-center text-base font-medium text-primary transition hover:border-primary hover:bg-primary hover:text-white "
              } `}
            >
              {buttonText}
            </a>

          </div>
        </div>
      </>
    );
  };
  
  export default PricingCard;