import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { verifyLoginOtpApi } from "../service/MessageApi/VehicleVerifyApi";

export default function Otp() {
  const location = useLocation();
   const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  console.log(otp)
    const inputRefs = Array.from({ length: 4 }, () => React.createRef());
  const handleUserDataChange = (event) => {
   console.log(event)
     setOtp(event)

  };
   useEffect(() => {
     if (location.state && location.state.phone) {
       setPhone(location.state.phone);
     }
   }, [location.state]);
   const handleInputChange = (index, value) => {
     if (index < inputRefs.length - 1 && value !== "") {
       inputRefs[index + 1].current.focus();
     }
     const otp = inputRefs.map((ref) => ref.current.value).join("");
     handleUserDataChange(otp);
  };
  
  const handleVerify =async () => {
    const data = {
      phone: `+91${phone}`,
      otp: otp,
    };
    const res = await verifyLoginOtpApi(data);
    console.log(res,"is here")
    toast.success(`OTP Verified`, {
      normalColor: "green",
      placement: "top",
      duration: 2000,
      animationType: "zoom-in",
    });

    // navigate("/");
  }
  return (
    <div className="flex justify-center mt-5 h-auto ">
      <div className="bg-blue-50 p-8 rounded-xl shadow-md w-full max-w-lg ">
        <h2 className="text-2xl font-bold mb-4 flex justify-center">
          Verify OTP
        </h2>
        <div className="mb-4">
          <img
            className="h-48 w-88 mx-auto rounded-md"
            src={require("../assets/images/image.png")}
          />
          <p className="text-md font-semibold mt-4 flex justify-center">
            please verify youre 4 digit
          </p>
          <ToastContainer />
          <div className="flex justify-center  ">
            <div className="flex item-center  justify-between w-1/2  mb-4 mt-4">
              {inputRefs.map((ref, index) => (
                <div key={index} className=" justify-between ">
                  <input
                    ref={ref}
                    maxLength={1}
                    className="w-10 border rounded-md py-2 px-0 pl-3 text-gray-700 focus:outline-none focus:border-blue-500"
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={handleVerify}
            className={` w-full inset-y-0 right-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-20`}>
            Send OTP
          </button>
        </div>
      </div>
    </div>
  );
}
