import React, { useEffect, useState } from 'react';
import MobileFooterNavbar from '../components/MobileFooterNavbar';
import { fetchSafetyMessages } from '../service/MessageApi/MessageApi';
import { ToastContainer, toast } from 'react-toastify';
// import { Image } from 'react-native-elements';
// import { useNavigation } from '@react-navigation/native';
// import { ScrollView } from 'react-native-gesture-handler';
// import { useToast } from "react-native-toast-notifications";
// import { sendMessageApi } from '../../Service/MessageScreensApi/accessoryVerifyApi';
// import { CircularProgress } from 'react-spinners-kit';

const AccessoryMessages = () => {
    // const navigation = useNavigation();
    //   const route = useRoute();
    // const qrcode = route.params;
    //   const toast = useToast();

    const [selectedValue, setSelectedValue] = useState('');
    const [safetyMessages, setSafetyMessages] = useState([]);
    console.log(safetyMessages);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log("api is calling")
        // Fetch safety messages from the API
        fetch('https://vehiclesafety.idea2reality.tech/api/safety_message')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log("data", data);
                if (Array.isArray(data.safetymessages)) {
                    // Filter messages based on message_type === 'Other'
                    const otherMessages = data.safetymessages.filter(message => message.message_type === 'Other');
                    setSafetyMessages(otherMessages);
                } else {
                    console.error('Invalid API response format. Expected an array of messages.');
                }
            })
            .catch((error) => {
                console.error('Error fetching safety messages:', error);
            });
    }, []);






    const handleRadioPress = (value) => {
        setSelectedValue(value);
    };

    const handleMessageSend = async () => {
        if (!selectedValue) {
            // toast.show(`${'Please Select Message'} ✉️`, {
            //     type: "warning",
            //     placement: "top",
            //     duration: 2000,
            //     offset: 100,
            //     animationType: "zoom-in",
            // });
            return
        }
        // const message_id = selectedValue;
        // const qr_code_id = qrcode;
        // const data = {
        //   message_id,
        //   qr_code_id,
        // };
        setLoading(true);
        // const res = await sendMessageApi(data)
        // console.log(res);

        // if (res.status === 200) {
        //     setLoading(false);
        //     //   toast.show(`${res.responseData.message} ✉️`, {
        //     //     type: "success",
        //     //     placement: "top",
        //     //     duration: 2000,
        //     //     offset: 100,
        //     //     animationType: "zoom-in",
        //     //   });
        //     //   navigation.navigate('home')
        // } else {
        //     setLoading(false);
        //     //   toast.show(`${res.responseData.message} ✉️`, {
        //     //     type: "danger",
        //     //     placement: "top",
        //     //     duration: 2000,
        //     //     offset: 100,
        //     //     animationType: "zoom-in",
        //     //   });
        //     //   setLoading(false);
        // }
    }

    const visibleSafetyMessages = safetyMessages.slice(0, 8);

    return (
        <div className="flex justify-center mt-5 h-auto ">
            <ToastContainer />
            <div className="bg-blue-50 p-8 rounded-xl shadow-md w-full max-w-lg ">
                <div className="bg-blue-50 ">
                    <div className="mx-4 my-4">
                        <p className="text-base font-semibold">
                            Please select a reason why you want to contact the owner.
                        </p>
                    </div>

                    <div className="h-80 mx-4 my-2 overflow-y-auto">
                        {visibleSafetyMessages.map((message) => (
                            <label key={message.message_id} className="block">
                                <div className="flex items-center justify-between m-2">
                                    <div className="flex items-center">
                                        <img style={{ height: 20, width: 20, marginRight: 2 }} src={message.image_path} alt='image' />
                                        <span className="text-base font-normal text-black">{message.message}</span>
                                    </div>
                                    <input type="radio" className="mr-2 form-radio" value={message.message_id} checked={selectedValue === message.message_id} onChange={() => handleRadioPress(message.message_id)} />
                                </div>
                            </label>
                        ))}
                    </div>

                    <div className="flex justify-between mx-4 my-2">
                        <button onClick={handleMessageSend} className="w-2/5 h-10 rounded bg-blue-500 text-white font-semibold">Message</button>
                        <button className="w-2/5 h-10 rounded bg-blue-900 bg-opacity-50 text-white font-semibold">Private Call</button>
                    </div>

                    <div className="mx-4 my-2 mb-28">
                        <p className="text-sm font-normal">
                            Please make sure that you are sending the right message. You are helping
                            us create a safer and nicer community for yourself and those around you.
                            <br />
                            <span className="italic font-bold ">Thank you for using Find The Owner</span>
                        </p>
                    </div>

                    {loading && (
                        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center">
                            {/* <CircularProgress size={50} color="#478ffd" /> */}
                        </div>
                    )}

                    <MobileFooterNavbar />
                </div>
            </div>
        </div>
    );
}

export default AccessoryMessages;
