import { Link } from "react-router-dom";

const MobileFooterNavbar = () => {
  return (
    <div className="fixed bottom-5 left-0 h-20 right-0  flex justify-center">
      <div className="w-full sm:w-1/3 lg:w-1/3  h-20 sm:h-auto rounded-xl  bg-white text-gray-400 border border-gray-400 flex justify-center items-center">
        <div className="grid grid-cols-5 w-full items-center justify-items-center sm:w-auto font-medium ">
          <Link to="/">
            <button
              type="button"
              className=" items-center  hover:text-blue-400 focus:text-blue-400 active:text-blue-400 py-2 px-3 sm:px-5">
              <svg
                className="w-5 h-5 mb-1 sm:mb-2   group-hover:text-blue-600 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20">
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>

              <span className="text-sm  group-hover:text-blue-600 ">Home</span>
            </button>
          </Link>

          <Link to="/contact">
            <button
              type="button"
              className=" justify-center items-center hover:text-blue-400 focus:text-blue-400 active:text-blue-400 py-2 px-3 sm:px-5">
              <svg
                className="w-5 h-5 mb-1 sm:mb-2 group-hover:text-blue-600 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20">
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              <span className="text-sm group-hover:text-blue-600 ">
                Contact
              </span>
            </button>
          </Link>
          <Link to="/camera">
            <button
              type="button"
              className=" justify-center items-center hover:text-blue-400 focus:text-blue-400 active:text-blue-400 py-2 px-3 sm:px-5">
              <svg
                className="w-5 h-5 mb-1 sm:mb-2 group-hover:text-blue-600 "
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 16">
                <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0v-3Zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5ZM.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5Zm15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5ZM4 4h1v1H4V4Z" />{" "}
                <path d="M7 2H2v5h5V2ZM3 3h3v3H3V3Zm2 8H4v1h1v-1Z" />
                <path d="M7 9H2v5h5V9Zm-4 1h3v3H3v-3Zm8-6h1v1h-1V4Z" />
                <path d="M9 2h5v5H9V2Zm1 1v3h3V3h-3ZM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8H8Zm2 2H9V9h1v1Zm4 2h-1v1h-2v1h3v-2Zm-4 2v-1H8v1h2Z" />{" "}
                <path d="M12 9h2V8h-2v1Z" />
              </svg>
              <span className="text-sm group-hover:text-blue-600 ">Scan</span>
            </button>
          </Link>

          <Link to="/">
            <button
              type="button"
              className=" justify-center items-center hover:text-blue-400 focus:text-blue-400 active:text-blue-400 py-2 px-3 sm:px-5">
              <svg
                className="w-5 h-5 mb-1 sm:mb-2 group-hover:text-blue-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2"
                />
              </svg>
              <span className="text-sm group-hover:text-blue-600 ">App</span>
            </button>
          </Link>
          <Link to="/">
            <button
              type="button"
              className=" justify-center items-center hover:text-blue-400 focus:text-blue-400 active:text-blue-400 py-2 px-3 sm:px-5">
              <svg
                className="w-5 h-5 mb-1 sm:mb-2 group-hover:text-blue-600 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20">
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              <span className="text-sm group-hover:text-blue-600 ">More</span>
            </button>
          </Link>
          {/* Other links go here */}
        </div>
      </div>
    </div>
  );
};

export default MobileFooterNavbar;
