import { MdCheckCircleOutline } from "react-icons/md";
import PricingCard from "./PricingCard";


const BestSelling=()=>{
    return (<>
 <section className="relative z-10 overflow-hidden bg-gray-100 pb-12 pt-10 lg:pt-[40px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-2 mb-[60px] text-center">
            
              <h2 className="mb-3 text-4xl font-extrabold leading-[1.208] text-dark">
              Some of our Best Selling Products
              </h2>
              <p className="text-xl text-body-color">
              Get your Parking Tag, Business Card or Tag for Menu today.
              </p>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap justify-center">
          <div className="mx-2 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-1 md:gap-4 xl:gap-10">
          <div className="wow animate__animated xl:animate__slower animate__zoomIn">
            <PricingCard
              type="VehicleSafety Parking tags"
              price="499"
              subscription="One Time"
              description="30 Days Money back, Dedicated Support and lifelong service."
              buttonText="Know More"
            >
            <div className="flex items-center gap-1">
               <MdCheckCircleOutline /> 
              <List> Lifetime service</List>
              </div>
              <div className="flex items-center gap-1">
               <MdCheckCircleOutline /> 
              <List> WhatsApp, SMS and Masked Call</List>
              </div>
              <div className="flex items-center gap-1">
               <MdCheckCircleOutline /> 
              <List> Add multiple numbers</List>
               </div>
               <div className="flex items-center gap-1">
               <MdCheckCircleOutline /> 
              <List>Add Emergency contact</List>
              </div>
            </PricingCard>
            </div>

            <div className="wow animate__animated xl:animate__slower animate__fadeInDown " >
            <PricingCard
              type="Business Card"
              price="599"
              subscription="One Time"
              description="All Cards and features one price. Get more business with our Smart business card."
              buttonText="Know More"
              active
            >
              <div className="flex items-center gap-1">
               <MdCheckCircleOutline /> 
              <List>Add Business Catalog / Services</List>
              </div>
              <div className="flex items-center gap-1">
               <MdCheckCircleOutline /> 
              <List>NFC and QR Based</List>
              </div>
              <div className="flex items-center gap-1">
               <MdCheckCircleOutline /> 
              <List>WhatsApp, Social Media Integrated</List>
              </div>
              <div className="flex items-center gap-1">
               <MdCheckCircleOutline /> 
              <List>Lead collection.</List>
              </div>
            </PricingCard>
            </div>

            <div className="wow animate__animated xl:animate__slower animate__zoomIn ">
            <PricingCard
              type="Menu tag"
              price="1499"
              subscription="One Time"
              description="We are giving away 2 years complete free to first 2000 Menu tags, try Now."
              buttonText="Know More"
            >
              <div className="flex items-center gap-1">
               <MdCheckCircleOutline /> 
              <List> Add unlimited Menu items</List>
              </div>
              <div className="flex items-center gap-2">
               <MdCheckCircleOutline /> 
              <List>Accept and manage orders</List>
              </div>
              <div className="flex items-center gap-2">
               <MdCheckCircleOutline /> 
              <List>Share menu on WhatsApp and email</List>
              </div>
              <div className="flex items-center gap-2">
               <MdCheckCircleOutline /> 
              <List>Waiter call and Daily Reports</List>
              </div>
            </PricingCard>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>)
};
export default BestSelling;

const List = ({ children }) => {
    return (
      <p className="text-base text-body-color ">{children}</p>
    );
  };