import Navbar from "./components/Navbar.jsx";
import Routes from "./routes/index.js";
import { useEffect } from "react";
import "animate.css";
import WOW from "wowjs";

function App() {
  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: "wow", // Apply wow to each element with the class 'wow'
      animateClass: "animated", // Default animation class name
      offset: 0, // Viewport offset to trigger the animation
      mobile: true, // Enable animations on mobile devices
      live: true, // Continuously watch for new elements
    });
    wow.init();
    return () => {
      wow.sync(); // Clean up WOW.js after unmounting the component
    };
  }, []);

  return (
    <>
      <Navbar />
      <Routes />
    </>
  );
}

export default App;
