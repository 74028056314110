import Hero from "../components/Hero";
import BestSelling from "../components/home/SellingProducts";
import Features from "../components/home/Features";
import TabSection from "../components/home/TabSection";
import Testimonial from "../components/home/Testimonial";
import Statistic from "../components/home/Stats";
import DownloadApp from "../components/home/DownloadAppSection";
import Service from "../components/home/Service";
import Footer from "../components/Footer";
import MobileFooterNavbar from "../components/MobileFooterNavbar";


const Home=()=>{
    return (<>
      <Hero /> 
      <Features/>
      <TabSection/>
      <Testimonial/>
      {/* <CallToAction /> */}
      <BestSelling/>
      <Statistic/>
      <DownloadApp/>
      <Service/>
      <Footer />
      <MobileFooterNavbar />
    </>)
};
export default Home;