import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

const Loader = ({ visible, height, width, color, ariaLabel, wrapperStyle }) => {
  return (
    <ThreeCircles
      visible={visible}
      height={height}
      width={width}
      color={color}
      ariaLabel={ariaLabel}
      wrapperStyle={wrapperStyle}
      wrapperClassName=""
    />
  );
};

export default Loader;
