import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import companyLogo from "../assets/images/logo.png";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [navbarSticky, setNavbarSticky] = useState(false);

  const code = 1234;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const isSticky = scrollPosition > 100;
      setNavbarSticky(isSticky);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`${
        navbarSticky ? "bg-blue-50 shadow-md" : "relative"
      } sticky top-0 z-50 bg-blue-50 container mx-auto p-6 transition-all ease-in-out rounded-2xl duration-300`}>
      <div className="flex items-center justify-between">
        <div className="pt-2 text-2xl font-bold">
          <Link to="/">
            <img className="h-10" src={companyLogo} alt="Logo" />
          </Link>
        </div>
        <div className="hidden space-x-6 md:flex">
          <Link to="/" className="hover:text-darkGrayishBlue">
            Home
          </Link>
          <Link
            to="/vehicle-verify/qrcode?code="
            className="hover:text-darkGrayishBlue">
            Message
          </Link>
          <Link
            to="/accessory-verify/qrcode"
            className="hover:text-darkGrayishBlue">
            Accessory Message
          </Link>
          <Link to="/shop" className="hover:text-darkGrayishBlue">
            Shop
          </Link>
          <Link to="/contact" className="hover:text-darkGrayishBlue">
            Contact
          </Link>
        </div>
        <Link
          to="/login"
          className="hidden p-3 px-6 pt-2 text-white bg-[#478ffd] rounded-full baseline md:block">
          Login
        </Link>
        <button
          className={
            toggleMenu
              ? "open block hamburger md:hidden focus:outline-none"
              : "block hamburger md:hidden focus:outline-none"
          }
          onClick={() => setToggleMenu(!toggleMenu)}>
          <span className="hamburger-top"></span>
          <span className="hamburger-middle"></span>
          <span className="hamburger-bottom"></span>
        </button>
      </div>
      <div className="md:hidden">
        <div
          className={
            toggleMenu
              ? "absolute flex flex-col items-center self-end py-8 mt-4 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md"
              : "absolute flex-col items-center hidden self-end py-8 mt-4 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md"
          }>
          <Link to="/">Home</Link>
          <Link to="/shop">Shop</Link>
          <Link to="/login">Login</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
