import { toast } from "react-toastify";

export const sendOtpApi = async (data) => {
  console.log("sendOtpApi", data);
  try {
    const response = await fetch(
      "https://vehiclesafety.idea2reality.tech/api/send_scan_qr_otp",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    console.log("sendOtpApi response data", response.status);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    console.log("sendOtpApi response data", response);
    const responseData = await response.json();
    console.log("sendOtpApi response data", responseData);

    return { responseData, status: response.status };
  } catch (error) {
    console.error("Error sending otp:", error);
    // throw error;
    return error;
  }
};
export const sendLoginApi = async (data) => {
  try {
    const response = await fetch(
      "https://vehiclesafety.idea2reality.tech/api/send_login_otp",
      {
        method: "POST",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    const responseData = await response.json();
    return { responseData, status: response.status };
  } catch (error) {
    console.error("Error Sending Otp", error);
    throw error;
  }
};


export const verifyLoginOtpApi = async (data) => {
  try {
    const response = await fetch(
      "https://vehiclesafety.idea2reality.tech/api/otp_login",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    const responseData = await response.json();

    return { responseData, status: response.status };
  } catch (error) {
    console.error("Error sending otp :", error);
    throw error;
  }
};


export const checkDuplicateNumberapi = async (mobile_number) => {
  try {
    const response = await fetch(
      "https://vehiclesafety.idea2reality.tech/api/check_duplicate_phone",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          mobile_number: mobile_number.phone_number,
        }),
      }, 
    );
    const data = await response.json();
    return { data, status: response.status };
  } catch (error) {
    console.log("Error inside catch here", error);
    
    throw error;
  }
};




export async function fetchQRDetails(qrCode) {
 
  try {
    const response = await fetch(
      `https://vehiclesafety.idea2reality.tech/api/qr_details?qr_code=${qrCode}`
    );
    if (!response.ok) {
      throw new Error("Network response wan not ok !");
    }
    const data = await response.json();
    console.log(data.data[0], "DAtais hereeeeeeeeeeeeeeeeeeeeeee");
    return data;
  } catch (error) {
    console.log("error in Fatching Qr Details", error);
  }
}

export const verifyOtpApi = async (data) => {
  try {
    const response = await fetch(
      "https://vehiclesafety.idea2reality.tech/api/verify_scan_qr_phone",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const responseData = await response.json();
    return { responseData, status: response.status };
  } catch (error) {
    console.error("Error sending otp :", error);
    return error;
  }
};

export const vehicleverifyApi = async (data) => {
  try {
    const response = await fetch(
      "https://vehiclesafety.idea2reality.tech/api/verify_vehicle",
      {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const responseData = await response.json();
    return { responseData, status: response.status };
  } catch (error) {
    console.error("Error sending otp :", error);
    return error;
  }
};
