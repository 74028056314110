import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import VehicleMessages from './VehicleMessages';
import EmergencyContact from './EmergencyContact';
import MobileFooterNavbar from '../components/MobileFooterNavbar';
import '../styles/ContacttabStyle.css';

function ContactTabs({ selectedTab ,qrcode }) {
  const [index, setIndex] = useState(selectedTab === 'second' ? 1 : 0);

  useEffect(() => {
   
    setIndex(selectedTab === 'second' ? 1 : 0);
  }, [selectedTab]);

  return (
    <div className="flex justify-center mt-5 h-auto">
      <div className="bg-blue-50 p-8 rounded-xl shadow-md w-full max-w-lg">
        <Tabs selectedIndex={index} onSelect={(index) => setIndex(index)}>
          <TabList style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Tab
              className="tab-button"
              style={{
                backgroundColor: index === 0 ? "#478ffd" : "transparent",
                color: index === 0 ? "white" : "#478ffd",
              }}>
              Contact Owner
            </Tab>
            <Tab
              className="tab-button"
              style={{
                backgroundColor: index === 1 ? "#EF4444" : "transparent",
                color: index === 1 ? "white" : "#EF4444",
              }}>
              Emergency Contact
            </Tab>
          </TabList>

          <TabPanel>
            <div className="flex flex-col h-full bg-white">
              <VehicleMessages />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="flex flex-col h-full bg-white">
              <EmergencyContact qrcode={qrcode} />
            </div>
          </TabPanel>
        </Tabs>
        <MobileFooterNavbar />
      </div>
    </div>
  );
}

export default ContactTabs;
