import { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import img1 from '../../assets/images/hero_image.png';
import img2 from '../../assets/images/appimg.png';
import img3 from '../../assets/images/appimage2.png';
import 'react-tabs/style/react-tabs.css';

const TabSection=()=>{
    const [tabIndex, setTabIndex] = useState(0);

return (<>
   <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
   <div className="xl:flex pt-10 lg:flex justify-center items-center gap-6 mx-2">
      <TabList className="text-xs border-none sm:text-sm w-full flex justify-center md:flex-row lg:flex-col xl:flex-col lg:w-[200px] wow animate__animated xl:animate__slower animate__fadeInDown " >
        <Tab className="text-gray-700 font-semibold border-r-2 active:border-r-2 focus:border-black focus:border-r-2 cursor-pointer p-2">Parking Tags</Tab>
        <Tab className="text-gray-700 font-semibold border-r-2 active:border-r-2 focus:border-black focus:border-r-2 cursor-pointer p-2">Business Card</Tab>
        <Tab className="text-gray-700 font-semibold border-r-2 active:border-r-2 focus:border-black focus:border-r-2 cursor-pointer p-2">Door Tag</Tab>
        <Tab className="text-gray-700 font-semibold border-r-none lg:border-r-2 active:border-r-2 focus:border-black focus:border-r-2 cursor-pointer p-2">Menu Tags</Tab>
      </TabList>
      <TabPanel>
        <div className="flex flex-col justify-center mt-4 md:mt-0 md:flex-row items-center md:text-start text-center lg:w-[800px] wow animate__animated animate__slower animate__zoomIn ">
        <img className="h-[350px] lg:h-[400px]" src={img1} alt="img"/>
        <div className='flex flex-col gap-3'>
            <p>Vehicle Parking Tags</p>
            <h2 className="font-bold text-2xl">Give people option to contact you in case of any issue with your parked vehicle</h2>
           <p>lorem ipsum dolor sit amet, consectetur adip id, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ven</p>
           <div>
           <button className="text-white bg-black hover:bg-white hover:text-black border border-black transition duration-500 font-medium rounded-lg text-sm px-8 py-3 ">Shop Now</button>
           </div>
        </div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="flex flex-col justify-center mt-4 md:mt-0 md:flex-row items-center md:text-start text-center lg:w-[800px] wow animate__animated animate__slower animate__zoomIn ">
        <img className="h-[350px] lg:h-[400px]" src={img2} alt="img"/>
        <div className='flex flex-col gap-3'>
            <p>Business Card</p>
            <h2 className="font-bold text-2xl">Give people option to contact you in case of any issue with your parked vehicle</h2>
           <p>lorem ipsum dolor sit amet, consectetur adip id, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ven</p>
        </div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="flex flex-col justify-center mt-4 md:mt-0 md:flex-row items-center md:text-start text-center lg:w-[800px] wow animate__animated animate__slower animate__zoomIn ">
        <img className="h-[350px] lg:h-[400px]" src={img1} alt="img"/>
        <div className='flex flex-col gap-3'>
            <p>Door Tag</p>
            <h2 className="font-bold text-2xl">Give people option to contact you in case of any issue with your parked vehicle</h2>
           <p>lorem ipsum dolor sit amet, consectetur adip id, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ven</p>
        </div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="flex flex-col justify-center mt-4 md:mt-0 md:flex-row items-center md:text-start text-center lg:w-[800px] wow animate__animated animate__slower animate__zoomIn ">
        <img className="h-[350px] lg:h-[400px]" src={img3} alt="img"/>
        <div className='flex flex-col gap-3'>
            <p>Menu Tags</p>
            <h2 className="font-bold text-2xl">Give people option to contact you in case of any issue with your parked vehicle</h2>
           <p>lorem ipsum dolor sit amet, consectetur adip id, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ven</p>
        </div>
        </div>
      </TabPanel>
      </div>
    </Tabs>
    </>)
};

export default TabSection;