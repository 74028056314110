import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";

const Service = () => {
  return (
    <div className="px-4 pb-28 lg:pb-12 md:pt-20 lg:pt-10 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          {/* <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              Latest Updates
            </p> */}
        </div>
        <h2 className=" mb-6 font-sans font-extrabold leading-none tracking-tight text-gray-900 text-4xl text-center">
          Latest Updates
        </h2>
        <p className=" text-gray-700 text-center text-lg">
          Checkout our updates to know about our services.
        </p>
      </div>
      <div className="grid gap-8 row-gap-6 lg:grid-cols-2">
        <div className="border p-10 text-center lg:text-start wow animate__animated animate__slideInDown " >
          <h6 className="mb-3 text-xl font-bold leading-5">The deep ocean</h6>
          <p className="mb-3 text-sm text-gray-900">
            A flower in my garden, a mystery in my panties. Heart attack never
            stopped old Big Bear. I didn't even know we were calling him Big
            Bear. We never had the chance to.
          </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a>
        </div>
        <div className=" text-center lg:text-start border p-10 wow animate__animated animate__slideInDown ">
          <h6 className="mb-3 text-xl font-bold leading-5">When has justice</h6>
          <p className="mb-3 text-sm text-gray-900">
            Rough pomfret lemon shark plownose chimaera southern sandfish
            kokanee northern sea robin Antarctic cod. Yellow-and-black triplefin
            gulper South American Lungfish mahi-mahi, butterflyfish glass
            catfish soapfish ling gray mullet!
          </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a>
        </div>

        <div className=" text-center lg:text-start border p-10 wow animate__animated animate__slideInUp ">
          <h6 className="mb-3 text-xl font-bold leading-5">Organically grow</h6>
          <p className="mb-3 text-sm text-gray-900">
            A slice of heaven. O for awesome, this chocka full cuzzie is as
            rip-off as a cracker. Meanwhile, in behind the bicycle shed,
            Hercules Morse, as big as a horse and Mrs Falani were up to no good
            with a bunch of crook pikelets.
          </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors
             duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a>
        </div>

        <div className=" text-center lg:text-start border p-10  wow animate__animated animate__slideInUp">
          <h6 className="mb-3 text-xl font-bold leading-5">
            A slice of heaven
          </h6>
          <p className="mb-3 text-sm text-gray-900">
            Disrupt inspire and think tank, social entrepreneur but preliminary
            thinking think tank compelling. Inspiring, invest synergy capacity
            building, white paper; silo, unprecedented challenge B-corp
            problem-solvers.
          </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a>
        </div>
      </div>

      <div className="flex justify-center mt-10">
        <Link to="/blog">
        <button className="flex items-center bg-transparent hover:bg-black text-gray-700 font-semibold
         hover:text-white py-3 px-6 duration-500 border border-gray-500 hover:border-transparent rounded">
          View All Posts <IoIosArrowRoundForward size={24}/>
        </button>
        </Link>
      </div>
    </div>
  );
};

export default Service;