import image1 from "../assets/images/hero_image.png";
import image2 from "../assets/images/product2.jpg";
import image3 from "../assets/images/product3.jpg";
import image4 from "../assets/images/product4.png";

export const ProductData = [
    {
        id:"1",
        img:image1,
        title:"Menu Tag for Restaurant and cafe, Call Waiter, Share Menu online, Get more customers.",
        rating:"5",
        price:"2499",
        discountPrice:"1499",
    },
    // {
    //     id:"2",
    //     img:image2,
    //     title:"Let people contact you incase you are not at home, Leave message for someone expected",
    //     rating:"5",
    //     price:"999",
    //     discountPrice:"440",
    // },
    // {
    //     id:"3",
    //     img:image3,
    //     title:"NFC and QR enabled Smart NGF132 Business Card, Best for freelancers and Influencers",
    //     rating:"5",
    //     price:"999",
    //     discountPrice:"599",
    // },
    // {
    //     id:"4",
    //     img:image4,
    //     title:"NGF132 Car and Bike Parking Tag, Contact Vehicle Owner without sharing contact details.",
    //     rating:"5",
    //     price:"499",
    //     discountPrice:"499",
    // }
]