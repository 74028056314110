import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import News from "../pages/News";
import Contact from "../pages/Contact";
import Blog from "../pages/Blog";
import Shop from "../pages/Shop";
import ProductDetail from "../pages/ProductDetail";
import VehicleVerify from "../pages/VehicleVerify";
import AccessoryVerifyScreen from "../pages/AccessoryVerify";
import ContactOwner from "../pages/ContactOwner";
import AccessoryMessages from "../pages/AccessoryMessages";
import Camera from "../pages/Camera";
import Login from "../pages/Login";
import Otp from "../pages/Otp";

const NavRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/otp" element={<Otp />} />
        <Route exact path="/shop" element={<Shop />} />
        {/* <Route exact path="/camera" element={<Camera />} /> */}
        <Route exact path="/news" element={<News />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route
          path="/vehicle-verify/qrcode"
          element={<VehicleVerify />}
        />
        <Route
          exact
          path="/accessory-verify/qrcode"
          element={<AccessoryVerifyScreen />}
        />
        <Route exact path="/productdetail" element={<ProductDetail />} />
        <Route exact path="/ContactOwner" element={<ContactOwner />} />
        <Route
          exact
          path="/AccessoryMessages"
          element={<AccessoryMessages />}
        />
      </Routes>
    </>
  );
};

export default NavRoutes;
