import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import {
  fetchSafetyMessages,
  sendMessageApi,
} from "../service/MessageApi/MessageApi";
import { useNavigate } from "react-router-dom";
// import { Image } from 'react-native-elements';
// import { useNavigation } from '@react-navigation/native';
// import { ScrollView } from 'react-native-gesture-handler';
// import { useToast } from "react-native-toast-notifications";
// import { sendMessageApi } from '../../Service/MessageScreensApi/accessoryVerifyApi';
// import { CircularProgress } from 'react-spinners-kit';

const VehicleMessages = () => {
  // const navigation = useNavigation();
  //   const route = useRoute();
  // const qrcode = route.params;
  //   const toast = useToast();

  const [selectedValue, setSelectedValue] = useState("");
  const [safetyMessages, setSafetyMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  console.log(selectedValue);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const messages = await fetchSafetyMessages();
        setSafetyMessages(messages);
        setLoading(false);
      } catch (error) {
        // Handle error
        console.error("Error fetching safety messages:", error);
        toast.error(`Error fetching safety messages ✉️`, {
          position: "top-center",
        });
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleRadioPress = (value) => {
    setSelectedValue(value);
  };

  const handleMessageSend = async () => {
    if (!selectedValue) {
      toast.warning(`Please Select Message`, {
        position: "top-center",
      });
      return;
    }
    const message_id = selectedValue;
    const qr_code_id = "qrcode";
    const data = {
      message_id,
      qr_code_id,
    };
    setLoading(true);
    const res = await sendMessageApi(data);
    console.log(res);

    if (res.status === 200) {
      setLoading(false);
      toast.success(`${res.responseData.message} ✉️`, {
        position: "top-center",
      });
      navigate("/");
      
    } else {
      setLoading(false);
      toast.error(
        `${res?.responseData?.message || res?.error || "Network Error"}`,
        {
          position: "top-center",
        }
      );
    }
  };

  const visibleSafetyMessages = safetyMessages.slice(0, 8);

  return (
    <div className="bg-blue-50">
      <ToastContainer />
      <div className="mx-4 my-4">
        <p className="text-base font-semibold">
          Please select a reason why you want to contact the owner.
        </p>
      </div>

      <div>
        {visibleSafetyMessages.map((message) => (
          <label key={message.message_id} className="block">
            <div className="flex items-center justify-between m-2">
              <div className="flex items-center">
                <img
                  className="mx-4 my-1.5 "
                  src={message.image_path}
                  alt="image"
                />
                <span className="text-base font-normal text-black">
                  {message.message}
                </span>
              </div>
              <input
                type="radio"
                className="mr-2 form-radio bg-blue-50 "
                value={message.message_id}
                checked={selectedValue === message.message_id}
                onChange={() => handleRadioPress(message.message_id)}
              />
            </div>
          </label>
        ))}
      </div>

      <div className="flex justify-between mx-4 my-2">
        <button
          onClick={handleMessageSend}
          className="w-2/5 h-10 rounded bg-blue-500 text-white font-semibold">
          Message
        </button>
        <button className="w-2/5 h-10 rounded bg-blue-900 bg-opacity-50 text-white font-semibold">
          Private Call
        </button>
      </div>

      <div className="mx-4 my-2 mb-28">
        <p className="text-sm font-normal">
          Please make sure that you are sending the right message. You are
          helping us create a safer and nicer community for yourself and those
          around you.
          <br />
          <span className="italic font-bold">
            Thank you for using Find The Owner
          </span>
        </p>
      </div>

      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-white">
            <i className="fas fa-spinner fa-spin text-4xl"></i>
            <Loader
              visible={true}
              height={100}
              width={100}
              color="#478ffd"
              ariaLabel="three-circles-loading"
              wrapperStyle={{}}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleMessages;
