const Statistic = () => {
    return (
    <div>

    
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="text-center"> 
     <h2 className="text-4xl font-extrabold">Checkout our Numbers</h2>
     <p className="mt-4">With more than 1L Active tags and 10k+ APP downloads, we have some great feedback.</p>
    </div>
        <div class="grid grid-cols-2 row-gap-8 gap-6 md:grid-cols-4 mt-20">
          <div class="text-center md:border-r wow animate__animated animate__slideInDown ">
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">144K</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Downloads
            </p>
          </div>
          <div class="text-center md:border-r wow animate__animated animate__zoomIn " >
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">12.9K</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Subscribers
            </p>
          </div>
          <div class="text-center md:border-r  wow animate__animated animate__zoomIn "  >
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">48.3K</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Users
            </p>
          </div>
          <div class="text-center  wow animate__animated animate__slideInDown ">
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">24.5K</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Cookies
            </p>
          </div>
        </div>
      </div>
      </div>
    );
  };

  export default Statistic;