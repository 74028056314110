import Form from "../components/contact/leftsection&form";


const Contact=()=>{
 return (<>
   <Form />
   
</>)
}

export default Contact;