import ReactStars from 'react-stars'
import { ProductData } from '../data/ProductData';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import MobileFooterNavbar from '../components/MobileFooterNavbar';

const Shop = () => {
    return (<>

        <div className="h-[200px] mt-6 rounded-xl mx-10 bg-blue-100">
            <div className="p-6 ">
                <div className="flex flex-col gap-4">
                    <h2 className="text-4xl font-bold">Shop</h2>
                    <p>Home / Shop</p>
                </div>
            </div>
        </div>

        <div className="px-12 sm:px-4 md:px-10 lg:px-40 py-10 pb-24 md:pb-10">
            <div>
                <h2 className="text-2xl font-bold">Shop for VehicleSafety Products.</h2>
            </div>

            <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 mt-10 gap-8">
                    {
                        ProductData.map((val) => {
                            return (<>
                                <Link to="/productdetail">
                                    <div className="max-w-sm p-5 bg-white border border-gray-200 rounded-lg shadow">
                                        <img className="rounded-t-lg w-full " src={val?.img} alt="img" />
                                        <div className="mt-4">
                                            <ReactStars
                                                count={5}
                                                value={val?.rating}
                                                size={18}
                                                color2={'#ffd700'} />
                                            <p className="mb-3 text-sm font-normal">{val?.title}</p>
                                            <div className="flex gap-3">
                                                <h2 className="text-xl font-bold">Rs:{val?.discountPrice}</h2>
                                                <strike className="text-xl">{val?.price}</strike>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </>)
                        })
                    }
                </div>
            </div>
        </div>
        <Footer />
        <MobileFooterNavbar />
    </>)
}

export default Shop;