import pro1 from "../assets/images/hero_image.png";
import pro2 from "../assets/images/appimage2.png";
import pro3 from "../assets/images/appimg.png"
import { useState } from "react";

const ProductDetail = () => {
    const [img,setImg]=useState(pro1);

    return (<>

        <section className="py-10 font-poppins bg-white">
            <div className="max-w-6xl px-4 mx-auto">
                <div className="flex flex-wrap mb-24 -mx-4">
                    <div className="w-full px-4 mb-8 md:w-1/2 md:mb-0">
                        <div className="sticky top-0 overflow-hidden ">
                            <div className="relative mb-6 lg:mb-10 lg:h-96"> 
                                <img className="object-contain w-full lg:h-full" src={img} alt="" />                 
                            </div>
                            <div className="flex -mx-2 md:flex justify-center">
                                <div className="w-1/2 p-2 sm:w-1/4">
                                    <button className="block border border-gray-200 hover:border-blue-400" onClick={()=>setImg(pro2)}>
                                        <img className="object-contain w-full lg:h-28" src={pro2} alt="img" />
                                    </button>
                                </div>
                                <div className="w-1/2 p-2 sm:w-1/4">
                                    <button className="block border border-gray-200 hover:border-blue-400" onClick={()=>setImg(pro1)}>
                                        <img className="object-contain w-full lg:h-28" src={pro1} alt="img" />
                                    </button>
                                </div>
                                <div className="w-1/2 p-2 sm:w-1/4">
                                    <button className="block border border-gray-200 hover:border-blue-400" onClick={()=>setImg(pro3)}>
                                        <img className="object-contain w-full lg:h-28" src={pro3} alt="img" />
                                    </button>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                    <div className="w-full px-4 md:w-1/2">
                        <div className="lg:pl-20">
                            <div className="mb-6 ">
                                <span className="px-2.5 py-0.5 text-xs text-blue-600 bg-blue-100 ">
                                In stock</span>
                                <h2 className="max-w-xl mt-6 mb-6 text-xl font-bold leading-loose tracking-wide text-black md:text-2xl">
                                Menu Tag for Restaurant and cafe, Call Waiter, Share Menu online, Get more customers.
                                </h2>
                                <div className="flex flex-wrap items-center mb-6">
                                    <ul className="flex mb-4 mr-2 lg:mb-0">
                                        <li>
                                            <a href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-4 mr-1 text-red-500  bi bi-star " viewBox="0 0 16 16">
                                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z">
                                                    </path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-4 mr-1 text-red-500  bi bi-star " viewBox="0 0 16 16">
                                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z">
                                                    </path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-4 mr-1 text-red-500 bi bi-star " viewBox="0 0 16 16">
                                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z">
                                                    </path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-4 mr-1 text-red-500 bi bi-star " viewBox="0 0 16 16">
                                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z">
                                                    </path>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                    <a className="mb-4 text-xs underline hover:text-blue-600 lg:mb-0" href="#">
                                        View the acer store
                                    </a>
                                </div>
                                <p className="inline-block text-2xl font-semibold text-gray-700 ">
                                    <span>Rs.1499.00</span>
                                    <span className="ml-3 text-base font-normal text-gray-500 line-through">Rs.2499.00</span>
                                </p>
                            </div>
                            <div className="py-6 mb-6 border-t border-b border-gray-200">
                                <p className="mt-2 text-sm text-gray-600">
                                Convert your Traditional Menu into Digital Menu, Anyone can Scan the QR to access your Digital menu. - Create a complete Digital menu - Accept Orders from the Table - calculate Bill Automatically - Let people check your men..
                                </p>
                            </div>

                            <div className="flex gap-4 mb-6">
                                <a href="#" className="w-full px-4 py-3 text-center text-gray-100 bg-blue-600 border border-transparent hover:border-blue-500 hover:text-blue-700 hover:bg-blue-100 rounded-xl">
                                    Buy now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default ProductDetail;