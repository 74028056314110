import React, { useState } from 'react';
// import Backbutton from '../component/Backbutton';
// import ContactTabs from './ContactTabsComponent';
// import Icon from "react-native-vector-icons/Entypo";
// import Subheader from '../component/SubHeader';
import { RxCrossCircled } from "react-icons/rx";
import ContactTabs from './ContactTabs';
import {  useLocation, } from 'react-router-dom';


const ContactOwner = ({ navigation }) => {
    const [modalVisible, setModalVisible] = useState(true);
    const [selectedTab, setSelectedTab] = useState(null);
    const qrcode=useLocation().state.data
  
    const handleTabSelection = (tab) => {
        setSelectedTab(tab);
        closeModal();
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    return (
        <div className="flex flex-col h-screen bg-white">
        
            {modalVisible && (
                <div className="fixed top-0 left-0 w-full h-full bg-black opacity-70 z-50"></div>
            )}

            <div className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 ${modalVisible ? 'block' : 'hidden'}`}>
                <div className="bg-white rounded-lg p-6 w-80 shadow-lg">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-xl font-bold">Select Option</h2>
                        <button onClick={closeModal}>
                            <RxCrossCircled />
                            {/* <Icon
                                name={"circle-with-cross"}
                                color="#e74c3c"
                                size={30}
                                style={{ marginTop: -18 }}
                            /> */}
                        </button>
                    </div>
                    <div className="h-px bg-gray-300 w-full mb-6"></div>

                    <button className="bg-blue-500 w-full text-white px-4 py-2 rounded-md mb-6" onClick={() => handleTabSelection('first')}>
                        Contact the Primary Owner
                    </button>
                    <button className="bg-red-500 w-full text-white px-4 py-2 rounded-md" onClick={() => handleTabSelection('second')}>
                        Emergency!!! Contact Relative
                    </button>

                    <p className="text-black m-4 ">★ In case of emergency, you must contact with the owner's relative</p>
                </div>
            </div>

            <ContactTabs selectedTab={selectedTab} qrcode={qrcode} />

        </div>
    );
};

export default ContactOwner;
