import AndImg from '../../assets/images/appimage.png';
import IOSImg from '../../assets/images/ios_image.png'

const DownloadApp=()=>{
  return(<>
  <div className="conatiner pt-10">
     <div className="flex flex-col gap-3 text-center mb-10">
     <h1 className="text-4xl font-extrabold">Download app today!</h1>
     <p className="text-xl">Download app for Android today — it's free.</p>
     </div>
     <div className="flex flex-col md:flex-row sm:items-center justify-center gap-4 md:gap-6 xl:gap-20 mx-4 md:mx-4 lg:mx-10">
       <div className="relative  bg-blue-50 h-[500px] sm:w-[400px] sm:h-[550px] rounded-3xl wow animate__animated xl:animate__slower animate__fadeInLeft ">
           <div className='p-10'>
            <h1 className="text-4xl font-bold">Android</h1>
            <p className="mt-2 text-gray-600">Download app for Android today — it's free.</p>
            <button className="w-full sm:w-auto mt-6 bg-black hover:bg-white hover:text-black focus:ring-4 focus:ring-gray-300 duration-500 text-white rounded-lg inline-flex items-center justify-center px-6 py-3">
				<svg className="mr-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play"
					role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
					<path fill="currentColor"
						d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z">
					</path>
				</svg>
				<div className="text-left">
					{/* <div className="mb-1 text-xs">Download for</div> */}
					<div className="-mt-1 font-sans text-sm font-semibold">Download for Android</div>
				</div>
			</button>
           </div>
           <div className="flex justify-center">
           <img className="absolute bottom-0 rounded-b-2xl" src={AndImg} alt="img"/>
           </div>
       </div>

       <div className="relative bg-blue-50 h-[500px] sm:w-[400px] sm:h-[550px] rounded-3xl wow animate__animated xl:animate__slower animate__fadeInRight ">
       <div className="flex flex-col justify-between p-10">
            <h1 className="text-4xl font-bold">iOS & iPadOS</h1>
            <p className="mt-2 text-gray-600">Download app for iOS today — it's free.</p>
            <div>
            <button className="w-full sm:w-auto mt-6 bg-white text-black hover:bg-black hover:text-white focus:ring-4 focus:ring-gray-300 duration-500 rounded-lg inline-flex items-center justify-center px-6 py-3">
            <svg class="mr-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple"
					role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
					<path fill="currentColor"
						d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
					</path>
				</svg>
				<div className="text-left">
					{/* <div className="mb-1 text-xs">Download for</div> */}
					<div className="-mt-1 font-sans text-sm font-semibold">Download for iOS</div>
				</div>
			</button>
      </div>
           </div>
           <div className="flex justify-center ">
           <img className="absolute bottom-0 rounded-b-2xl" src={IOSImg} alt="img"/>
           </div>
       </div>
     </div>   
     </div>
  </>)
};
export default DownloadApp;